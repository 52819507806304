import React, { useState, useRef } from "react";
import LogoCNC from "../../images/logo-cnc.png";
import LogoCasaLegado from "../../images/logo-casalegado.png";
import { Link } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useWindowDimensions from "../../styles/useWindowDimensions";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import ChatComponent from "../ChatIA/ChatIA";

import PowerBI from "../PowerBI";

const WORKSPACE_ID = "228f80ba-5302-4260-b5f9-64d67cb07866";
const REPORT_ID = "00faf097-9cf8-4dcd-bd92-955d3f619086";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 110,
    [theme.breakpoints.up("sm")]: {
      width: 135,
    },
  },
  logo2: {
    width: 90,
    [theme.breakpoints.up("sm")]: {
      width: 115,
    },
  },
  logoSpacing: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
    display: "flex",
  },
  logoSpacing2: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
    display: "flex",
  },
  username: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "unset",
    },
  },
}));

export default function Visor1CasaLegado(props) {
  const { theme, API_DEFAULT, setShowBackdrop, setAuth, clientUUID } = props;
  const [accessToken, setAccessToken] = props.accessToken;
  const [username, setUsername] = props.username;
  const classes = useStyles();
  const refPBI = useRef(null);
  const [pbiEmbed, setPbiEmbed] = useState({});

  const { width } = useWindowDimensions(refPBI, pbiEmbed);

  return (
    <React.Fragment>
      <AppBar position="static" style={{ background: "#FCB134" }}>
        <Toolbar>
          <div className={classes.logoSpacing}>
            <a
              style={{ display: "contents" }}
              href={"https://www.centronacionaldeconsultoria.com/"}
            >
              <img src={LogoCNC} alt="logo-cnc" className={classes.logo} />
            </a>
          </div>
          <div className={classes.logoSpacing2}>
            <a
              style={{ display: "contents" }}
              href={"https://casalegadobogota.com/"}
            >
              <img
                src={LogoCasaLegado}
                alt="logo-CasaLegado"
                className={classes.logo2}
              />
            </a>
          </div>
          <Typography variant="h5" style={{ flexGrow: 1, fontWeight: 500 }}>
            Reporte de reservas y financiero
          </Typography>
          <React.Fragment>
            <Typography
              className={classes.username}
              style={{
                fontSize: 14,
                fontWeight: 500,
                marginRight: theme.spacing(2),
                fontStyle: "italic",
              }}
              variant="body1"
            >
              @{username.toLowerCase()}
            </Typography>
            <IconButton
              style={{ marginRight: theme.spacing(1) }}
              component={Link}
              edge="start"
              color="inherit"
              to={`/client/${clientUUID}/home`}
            >
              <HomeIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                localStorage.removeItem("token-CasaLegado");
                localStorage.removeItem("auth-CasaLegado");
                localStorage.removeItem("username-CasaLegado");
                setAccessToken("");
                setAuth(false);
                setUsername("");
              }}
            >
              <LogoutIcon />
            </IconButton>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: theme.spacing(2) }}>
        <Container maxWidth="xl">
          <PowerBI
            pbi={[pbiEmbed, setPbiEmbed]}
            refPBI={refPBI}
            width={width}
            theme={theme}
            accessToken={accessToken}
            API_DEFAULT={API_DEFAULT}
            WORKSPACE_ID={WORKSPACE_ID}
            REPORT_ID={REPORT_ID}
            setShowBackdrop={setShowBackdrop}
          />
        </Container>
      </div>
      <ChatComponent />
    </React.Fragment>
  );
}
